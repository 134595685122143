import React, { Suspense } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home/Home";
import { CircularProgress } from "@mui/material";

const Terms = React.lazy(() => import("./pages/Terms/Terms"));
const Return = React.lazy(() => import("./pages/Return/Return"));
const Privacy = React.lazy(() => import("./pages/Privacy/Privacy"));
const Prescription = React.lazy(() =>
  import("./pages/Prescription/Prescription")
);
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const MinorAliments = React.lazy(() =>
  import("./pages/MinorAliments/MinorAliments")
);
const Services = React.lazy(() => import("./pages/Services/Services"));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" index element={<Home />} />
            <Route
              path="/Services"
              index
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <Services />
                </Suspense>
              }
            />
            <Route
              path="/MinorAliments"
              index
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <MinorAliments />
                </Suspense>
              }
            />
            <Route
              path="/Prescription"
              index
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <Prescription />
                </Suspense>
              }
            />
            <Route
              path="/Contact"
              index
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <Contact />
                </Suspense>
              }
            />
            <Route
              path="/Privacy_policy"
              index
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <Privacy />
                </Suspense>
              }
            />
            <Route
              path="/Terms"
              index
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <Terms />
                </Suspense>
              }
            />
            <Route
              path="/Returning_policy"
              index
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <Return />
                </Suspense>
              }
            />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
