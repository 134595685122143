import React from "react";
import { Container, useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper/modules";
import "./TestimonialsSwiper.scss";
import Sunny from "../../assets/Sunny.png";
import Ala from "../../assets/Ala.png";
import Gigi from "../../assets/Gigi.png";
import Karima from "../../assets/Karima.png";
import Faheem from "../../assets/Faheem.png";
import { Rating } from "@mui/material";
import SwiperNavButton from "./SwiperNavButton";

const TestimonialsSwiper = () => {
  const small = useMediaQuery("(max-width:600px)");
  const medium = useMediaQuery("(min-width:600px)");
  const large = useMediaQuery("(min-width:1200px)");

  const testimonail = [
    {
      title: "“Providing options to reduce your expenses”",
      note: "Out of a lot of pharmacies in the area, this is one of the best. The owner of the pharmacy, Rafaat, is a good, sympathetic guy, discussing and sharing details with you and keeping in view your aspects shares details. Providing options, alternatives and possible ways to reduce your expenses. It is located in a plaza where a lot of other businesses are working too.\nParking is free.",
      author: "Sunny M",
      photo: Sunny,
      stars: 5,
    },
    {
      title: "“Raffat saved us many times”",
      note: "Raffat has been serving my family as well as my parents for many, many years. He noticed many dangerous medication interactions prescribed by our doctors.  He always gets our meds if not in stock the next day.  He saved us many times.  He is compassionate and knowledgeable. We will always be loyal :) His Polish clients :)",
      author: "Ala Makota",
      photo: Ala,
      stars: 5,
    },
    {
      title: "“Best pharmacy I have ever been to”",
      note: "Best pharmacy I have ever been to. Rafaat is an amazing pharmacist and is always ready to answer any questions I may have. My whole family comes here. We've always had a great experience.",
      author: "Medina Ahmadi",
      photo: Sunny,
      stars: 5,
    },
    {
      title: "“My pharmacy for the past 7 years”",
      note: "This family-owned pharmacy provides exceptional and friendly service! This has been my pharmacy for the past 7 years. Rafet is extremely helpful and goes above and beyond for his customers. If you want a personal experience this is the best pharmacy :)",
      author: "Lay",
      photo: Sunny,
      stars: 5,
    },
    {
      title: "“goes above and beyond what is expected”",
      note: "I have a large family. We have been dealing with this pharmacist for over 14 years. I will not go anywhere else. Why won’t we go anywhere else because he goes above and beyond what is expected of a pharmacist. Always trusted always there for us.",
      author: "Gigi Mama",
      photo: Gigi,
      stars: 5,
    },
    {
      title: "“Raffat treats us as family”",
      note: "I always visit this pharmacy. The rates are ok and the pharmacist's behaviour is too good. He always deals with us with respect and treats us as a family. \n Thanks, Raffat ..... and stay blessed. ",
      author: "Harjyot Kaur",
      photo: Sunny,
      stars: 5,
    },
    {
      title: "“Reliable, you can count on”",
      note: "Reliable, you can count on. Very respectful. Definitely, I would recommend to anyone.",
      author: "Jeffrey C",
      photo: Sunny,
      stars: 5,
    },
    {
      title: "“Very caring and attentive”",
      note: "Raffat is the best pharmacist I’ve had. Very caring and attentive.      ",
      author: "Karima",
      photo: Karima,
      stars: 5,
    },
    {
      title: "“best service & best rate”",
      note: "Best pharmacy, best service and best rate.",
      author: "Faheem Sherwani",
      photo: Faheem,
      stars: 5,
    },
    {
      title: "“Rly good pharmacist”",
      note: "Rly good pharmacist",
      author: "Hamza Alrayiss",
      photo: Faheem,
      stars: 5,
    },
  ];

  return (
    <Container
      sx={{
        maxWidth: "100vw !important",
        paddingX: { xs: "0px !important", md: "auto" },
      }}>
      <div className="TestimonialsSwiper">
        <div className="testimonails">
          <Swiper
            slidesPerView={large ? 3.1 : medium ? 2 : small && 1.16}
            spaceBetween={large ? 24 : medium ? 20 : small && 12}
            modules={[FreeMode, Navigation]}
            navigation={true}
            className="mySwiper"
            freeMode={true}
            speed={400}>
            {testimonail.map((testimonail) => (
              <SwiperSlide>
                <div className="box">
                  <div className="textbox">
                    <p className="title">{testimonail.title}</p>
                    <p className="note">{testimonail.note}</p>
                  </div>
                  <div className="author">
                    {/* <img src={testimonail.photo} alt={testimonail.author} /> */}
                    <div className="name">
                      <div className="stars">
                        <Rating
                          color="success"
                          name="simple-controlled"
                          value={testimonail.stars}
                        />
                      </div>
                      <p>{testimonail.author}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="buttons">
              <SwiperNavButton />
            </div>
          </Swiper>
        </div>
      </div>
    </Container>
  );
};

export default TestimonialsSwiper;
