import React, { useEffect } from "react";
import Navbar from "./Navbar/Navbar";
import Banner from "./Navbar/Banner";
import Footer from "./Footer/Footer";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Banner />
      <Navbar />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
